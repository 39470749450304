import React, { useEffect } from "react";
import AboutUsFooter from "../components/aboutus/AboutUsFooter";
import Hero from "../components/aboutus/Hero";
import AboutUsWrapper from "../components/aboutus/AboutUsWrapper";
import { useScroll } from "../context/ScrollContext";
import { useLocation } from "react-router-dom";

const AboutPage = () => {
  const { featuresRef } = useScroll();
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div ref={featuresRef}>
      <Hero />
      <AboutUsWrapper />
      <AboutUsFooter />
    </div>
  );
};

export default AboutPage;
