import React from "react";
import arrowLines from "../../assets/supportArrowLines.svg";
import darkArrow from "../../assets/supportArrowGray.svg";
const ArticleCard = ({ title, handleClick }) => {
  return (
    <>
      <div
        role="button"
        onClick={handleClick}
        className="lg:w-[430px] sm:w-[295px] h-8 lg:mt-5 sm:mt-2 flex justify-between sm:items-center"
      >
        <p className="flex items-center gap-2 lg:text-xl sm:text-xs">
          <span>
            <img src={arrowLines} alt={arrowLines} />
          </span>
          {title}
        </p>

        <span>
          <img
            className="lg:mt-3 mt-2 lg:w-3 sm:w-2"
            src={darkArrow}
            alt=""
            width={12}
          />
        </span>
      </div>
      <div className="lg:w-[437px] sm:w-[295px] h-[1px] bg-[#7A7A7A] lg:mt-4 mt-2"></div>
    </>
  );
};

export default ArticleCard;
