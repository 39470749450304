import React from "react";
import "../../styles/aboutus.css";
import TeamMember from "./TeamMember";
import CryptoApp from "./CryptoApp";
import Values from "./values/Values";
const AboutUsWrapper = () => {
  return (
    <div className="2xl:min-h-[200vh] w-full aboutus-wrapper-bg  ">
      <TeamMember />
      <CryptoApp />
      <Values />
    </div>
  );
};

export default AboutUsWrapper;
