import React, { useState, useEffect, useMemo } from "react";
import { useWallet } from "../hooks/useWallet";
import "../styles/common.css";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { parseEther } from "ethers/lib/utils";
import axios from "axios";
import { toast } from "react-toastify";
import { entryPasses } from "../services/constant";
const Entrypass = () => {
  const [userBalance, setUserBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const {
    address,
    chainId,
    safeCallContract,
    safeSendTransaction,
    getChainConfig,
    isConnected,
  } = useWallet();
  const location = useLocation();
  const params = useParams();

  const {
    src,
    title,
    name,
    path,
    contract,
    chainIds: id,
  } = useMemo(() => {
    return entryPasses.find((e) => e.path === params.id) || {};
  }, [params.id]);

  const [mintOnChain, setMintOnChain] = useState(id?.[0]);

  useEffect(() => {
    if (chainId) {
      setMintOnChain(id.includes(Number(chainId)) ? Number(chainId) : id[0]);
    }
  }, [chainId]);

  useEffect(() => {
    if (!isConnected) {
      setUserBalance(0);
    }
  }, [isConnected]);
  useEffect(() => {
    const fetchUserBalance = async () => {
      const [errNFT, balanceNFT] = await safeCallContract({
        name: contract,
        method: "balanceOf",
        chainId: mintOnChain.toString(),
        args: [address],
      });
      if (errNFT) return;

      let totalBalance = +balanceNFT.toString();

      setUserBalance(totalBalance);
    };

    if (chainId) fetchUserBalance();
  }, [mintOnChain, address, id, loading]);

  const mintEntryPass = async () => {
    try {
      setLoading(true);
      const config = getChainConfig(false);
      if (!config) return;

      const mintWithApi = async () => {
        if (chainId === 56) {
          const [err, tx] = await safeSendTransaction({
            to: "0x5222d5467DC61aFc2EfA95Ef76dCDe411e6e1D35",
            value: "0.008",
          });

          if (err) throw new Error("Failed to send transaction.");

          const response = await axios.post(
            "https://api-v2.blazpay.com/api/nft/mintNFT",
            { accountId: address }
          );

          if (response.data.message === "NFT minted successfully") {
            setUserBalance(userBalance + 1);
            toast.success("NFT minted with BNB successfully");
          }
        } else {
          const response = await axios.post(
            "https://api-v2.blazpay.com/api/mint/mintEP",
            { userAddress: address }
          );

          if (response.data.message === "NFT minted successfully") {
            setUserBalance(userBalance + 1);
            toast.success("NFT minted! Head on to rewards dashboard!");
          }
        }
      };

      const mintWithContract = async () => {
        if (chainId !== mintOnChain) {
          toast.error(`Please switch to ${name} network.`);
          return;
        }

        const mintValue =
          config.features[contract] || config.features.mintValue;
        const [err, tx] = await safeCallContract({
          name: contract,
          method: "safeMint",
          chainId: mintOnChain.toString(),
          args: [
            address,
            {
              value: parseEther(String(mintValue)),
            },
          ],
        });

        if (err) return;

        toast.info("Transaction sent. Please wait...");
        await tx.wait();
        setUserBalance(userBalance + 1);
        toast.success(
          "Mint successful! Head to Rewards Dashboard now to start earning"
        );
      };

      if (config.features.apiMinting) {
        await mintWithApi();
      } else {
        await mintWithContract();
      }
    } catch (err) {
      if (err.code === -32603) {
        toast.error("Insufficient funds for gas");
      } else {
        toast.error(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  if (!path) return <Navigate to="/not-found" />;

  return (
    <section className="min-h-screen  overflow-x-hidden relative  w-full  ">
      <div
        style={{
          filter: "blur(160px)",
        }}
        className="absolute xl:h-[450px] h-[360px] xl:top-1/2 xl:-translate-y-1/2 z-[-1] top-20 xl:w-[320px] w-[200px] rounded-full bg-[#ff3503] -right-[160px]"
      />
      <div className="absolute 2xl:-left-[330px] xl:-left-[280px] -left-[250px] z-[-1] xl:top-1/2 top-16 xl:-translate-y-1/2 2xl:h-[660px] xl:h-[560px] h-[500px]  2xl:w-[660px] xl:w-[560px] w-[500px] rounded-full border border-[#434343]/60">
        <div className="absolute 2xl:inset-16 xl:inset-12 inset-10 rounded-full border border-[#434343]/60">
          <div className="absolute 2xl:inset-16 xl:inset-12 inset-10 rounded-full border border-[#434343]/60">
            <div
              style={{
                boxShadow:
                  "-12px 60px 50px rgba(0,0,0,0.8) inset,0px -20px 30px rgba(0,0,0,0.9) inset",
              }}
              className="absolute 2xl:inset-20  inset-16 rounded-full bg-[#ff3503]"
            />
          </div>
        </div>
      </div>
      <div className="xl:container pb-8 w-full xl:px-0 lg:px-16 md:px-12  2xl:pt-[20vh] md:pt-32 pt-16  mx-auto flex md:flex-row flex-col  md:items-start md:gap-y-0 gap-y-4 items-center justify-between">
        <div className="2xl:pt-20 xl:pt-12 flex md:w-1/2  w-full flex-col md:items-start items-center 2xl:pl-16 xl:pl-12">
          <h1 className="2xl:text-[64px] md:text-left text-center lg:text-5xl md:text-4xl sm:text-3xl text-2xl uppercase font-bold font-redHat gradient-heading text-uppercase">
            {title}
          </h1>
          <p className="xl:text-2xl lg:text-xl md:text-base sm:text-[14px] text-xs md:text-left text-center md:px-0  sm:px-16 px-8 text-white lg:mt-8 mt-5">
            Entrypass is Blazpay's gateway to its financial ecosystem, letting
            users earn Blazpoints through tasks. Holders gain exclusive perks,
            like Early Bird Rewards, priority in events, and contribute to
            platform refinement. Entrypass unlocks early airdrops, governance
            participation, enhanced features, and staking rewards in the BlazPay
            ecosystem
          </p>
          <div
            role="button"
            style={{
              boxShadow: "0 0 40px rgba(0,0,0,0.8) inset",
            }}
            className="xl:mt-8 lg:mt-6 mt-4 xl:h-16 md:h-[56px] h-12 relative xl:w-52 md:w-48 w-40 bg-[#a9a9a9] rounded-full"
          >
            <div className="absolute font-redHat xl:inset-x-4 inset-x-3 xl:inset-y-3 inset-y-2 rounded-full flex items-center justify-between lg:px-8 md:px-6 px-4 lg:text-base md:text-[14px] text-xs text-white font-bold bg-gradient-to-r from-[#a23701] via-[#cc4400] to-[#993300]">
              <span>You Own</span>
              <span>{userBalance}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:items-center md:items-end items-center md:w-1/2 w-full">
          <img
            style={{
              filter: "drop-shadow(0 0 40px rgba(0,0,0,0.8))",
            }}
            src={src}
            alt="entrypass"
            className="xl:h-[500px]  lg:h-[400px] h-[340px] "
          />
          <button
            onClick={mintEntryPass}
            disabled={loading}
            loading={loading}
            style={{
              boxShadow: "0 0 20px rgba(0,0,0,0.72)",
            }}
            className="xl:text-xl overflow-hidden group relative 2xl:mt-6 lg:mt-5 mt-4 lg:left-0 md:-left-10 lg:text-base text-[13px]  transition-all duration-250   font-semibold flex items-center gap-x-3 justify-center xl:px-16 lg:px-12 px-10 xl:py-4 py-2 bg-[#ff3503] text-white outline-none rounded-full"
          >
            <span className="absolute -left-64 block group-active:left-1/2 group-active:-translate-x-1/2 transition-all ease duration-[300ms]">
              Confirm
            </span>
            <span className="relative left-0 block group-active:left-64 transition-all ease duration-[300ms]">
              Get It Now
            </span>
            <HiArrowNarrowRight className="md:text-3xl text-xl relative left-0 block group-active:left-64 transition-all ease duration-[500ms]" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Entrypass;
