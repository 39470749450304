import { errors } from "ethers";
import { toast } from "react-toastify";

export const chainIdToHex = (chainId) => {
  const hexString = chainId.toString(16);
  return "0x" + hexString;
};

export const toastPromise = (promise, { pending, success, error }, options) => {
  return new Promise((resolve) => {
    const isStr = (value) => typeof value === "string";

    toast.promise(
      promise,
      {
        pending: {
          render: () => {
            return isStr(pending) ? pending : pending();
          },
        },
        success: {
          render: ({ data }) => {
            return isStr(success) ? success : success(data);
          },
        },
        error: {
          render: ({ data }) => {
            return isStr(error) ? error : error(data);
          },
        },
      },
      options
    );

    promise.finally(resolve);
  });
};

export const handleTransactionError = (error) => {
  switch (error.code) {
    case errors.ACTION_REJECTED:
      toast.error("You have rejected the transaction");
      break;
    case errors.INSUFFICIENT_FUNDS:
      toast.error("Insufficient funds");
      break;
    case errors.NETWORK_ERROR:
      toast.error("Network error, please try again");
      break;
    case errors.UNPREDICTABLE_GAS_LIMIT:
      toast.error("Unpredictable gas price, please try again");
      break;
    case -32603:
      toast.error("Insufficient funds");
      break;
    default:
      toast.error("Error occurred while processing the transaction");
      break;
  }
};
