import React from "react";

const HomeButton = ({ className, handleClick }) => {
  return (
    <button
      onClick={handleClick}
      className={`bg-[#FF3503] lg:px-9 py-2 md:px-7 px-4 rounded-full font-semibold hover:opacity-85 hover:scale-95 transition-all ease-in-out duration-300 ${className}`}
    >
      Go Home
    </button>
  );
};
export default HomeButton;
