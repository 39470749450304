import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "../../styles/common.css";
import "../../styles/slider.css";
import { EffectCoverflow, Pagination } from "swiper/modules";
import { entryPasses } from "../../services/constant";

export default function Slider() {
  const swiperRef = useRef(null);
  const navigate = useNavigate();
  return (
    <div className="lg:container w-full mx-auto">
      <div className="sm:w-11/12 w-full md:w-10/12 lg:w-9/12 2xl:w-8/12 mx-auto  my-10">
        <h1 className="text-center md:text-5xl text-3xl font-semibold gradient-label text-white">
          Explore
        </h1>
        <div className="relative w-full  mt-4 z-10">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            initialSlide={1}
            slidesPerView={3}
            loop={true}
            pagination={{
              clickable: true,
            }}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 10,
              modifier: 1,
              slideShadows: false,
            }}
            modules={[EffectCoverflow, Pagination]}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            className=""
          >
            {entryPasses.map((pass, index) => (
              <SwiperSlide
                className="relative group"
                key={index}
                onClick={() => navigate(`/entrypass/${pass.path}`)}
              >
                <div className="absolute group-hover:text-[#ff5500] transition-all duration-200 ease w-full md:text-xl sm:text-base text-xs font-bold flex justify-center text-white z-30 md:-top-7 sm:-top-5 -top-4">
                  {pass.isTestnet ? "Testnet" : "Mainnet"}
                </div>
                <div className="h-full w-full object-contain">
                  <img
                    src={pass.src}
                    alt={pass.title}
                    className="w-fit w-fit object-contain "
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            className="z-20 bg-[#2D2D2D] absolute flex justify-center items-center text-white xl:text-xl text-xs w-10 h-10 xl:w-16 xl:h-16 transition-all duration-250 ease hover:scale-[.90] rounded-full  border border-[#4f4f4f] cursor-pointer top-[40%] xl:-left-[4%] -left-[2%]"
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <FaArrowLeftLong />
          </div>
          <div
            className="z-20 bg-[#2D2D2D] absolute flex justify-center items-center text-white xl:text-xl text-xs w-10 h-10 xl:w-16 xl:h-16 transition-all duration-250 ease hover:scale-[.90] rounded-full  border border-[#4f4f4f] cursor-pointer top-[40%] xl:-right-[4%] -right-[2%]"
            onClick={() => swiperRef.current?.slideNext()}
          >
            <FaArrowRight />
          </div>
        </div>
      </div>
    </div>
  );
}
