import React from "react";
import "../../../styles/aboutus.css";
import "../../../styles/common.css";
import { FaUser } from "react-icons/fa";
import valuesTransparencyLogo from "../../../assets/valuesCard.svg";
const CardContainer = () => {
  return (
    <div
      style={{
        boxShadow: "0 0 40px rgba(200,200,200,0.16)",
      }}
      className=" xl:w-[1000px] xl:h-[1000px] lg:h-[900px] md:h-[700px] md:w-11/12 w-[98%] sm:h-[640px] xs:h-[500px] h-[480px] mx-auto mt-8 border-2 border-[#232323] flex flex-row md:gap-x-6 xs:gap-x-4 gap-x-2 lg:rounded-[40px] rounded-3xl lg:p-10 md:p-6 xs:p-4 p-3"
    >
      <div className="flex w-1/2 flex-col h-[100%] md:gap-y-6 xs:gap-y-4 gap-y-2">
        <ValuesItemCard
          className="sm:h-[55%] h-[52%]"
          gradient={"card-gradient-1"}
          title="Innovation"
        >
          <div
            style={{
              backdropFilter: "blur(22px)",
              boxShadow: "5px 5px 5px rgba(0,0,0,0.24)",
            }}
            className="xl:h-52 xl:w-52 lg:h-44 lg:w-44 sm:h-36 xs:h-24 h-20 sm:w-36  xs:w-24 w-20 lg:mt-8 mt-0 relative lg:top-0 top-2 group-hover:rotate-[180deg] group-hover:scale-[1.12] transition-all duration-[200ms] ease relative rounded-full bg-[#303030]/60"
          >
            <div
              style={{
                backdropFilter: "blur(18px)",
                boxShadow: "0 8px 8px rgba(0,0,0,0.24)",
              }}
              className="absolute lg:inset-8 sm:inset-5 inset-4 group-hover:bg-[#ff3503]  group-hover:scale-[1] transition-all duration-[800ms] ease rounded-full bg-[#525252]/30"
            >
              <div
                className="absolute lg:inset-7 sm:inset-6 xs:inset-4 inset-3 group-hover:scale-[0.96]  transition-all duration-[400ms] ease rounded-full bg-[#1c1b1b]"
                style={{
                  boxShadow: "0 10px 10px rgba(0,0,0,0.24)",
                }}
              />
            </div>
          </div>
          <TextCard
            className="text-gradient-1 sm:px-3 px-1 text-center sm:mt-5  mt-4"
            description="We continuously push the boundaries of technology by integrating AI and Cross chain experience to simplify decentralized finance, making crypto management smarter and more accessible for everyone"
          />
        </ValuesItemCard>
        <ValuesItemCard
          className="sm:h-[45%] h-[48%]"
          title="Collaboration"
          gradient={"bg-gradient-to-bl from-[#ff3503] to-[#000]"}
        >
          <div className="lg:h-40 lg:w-40 sm:h-36 xs:h-28 h-24 sm:w-36 xs:w-28 w-24 transition-all duration-[600ms] ease lg:group-hover:scale-[1.1] md:group-hover:scale-[0.9] scale-[0.8] group-hover:rotate-[180deg] rounded-full border-[3px] relative border-[#282524] group-hover:border-[#ff3503] lg:mt-8 mt-0">
            <div
              className="absolute lg:h-[60px] lg:w-[60px] xs:h-12 h-8 xs:w-12 w-8  rounded-full transition-all duration-[300ms] ease group-hover:scale-[0.8] group-hover:bg-[#ff3503] bg-[#282524] xs:top-4 top-3 xs:-left-5 -left-4"
              style={{
                boxShadow: "0 2px 20px rgba(0,0,0,0.12)",
              }}
            />
            <div
              className="absolute lg:h-[60px] lg:w-[60px] xs:h-12 h-8 xs:w-12 w-8  rounded-full transition-all duration-[600ms] ease group-hover:scale-[0.8] group-hover:bg-[#ff3503] bg-[#282524] xs:-bottom-5 -bottom-4 left-1/2 -translate-x-1/2"
              style={{
                boxShadow: "0 2px 20px rgba(0,0,0,0.12)",
              }}
            />
            <div
              className="absolute lg:h-[60px] lg:w-[60px]  xs:h-12 h-8 xs:w-12 w-8 rounded-full transition-all duration-[900ms]  ease group-hover:scale-[0.8] group-hover:bg-[#ff3503] bg-[#282524] xs:top-4 top-4 xs:-right-5 -right-4"
              style={{
                boxShadow: "0px 2px 40px rgba(0,0,0,0.12)",
              }}
            />
          </div>
          <TextCard
            className="text-gradient-2 md:px-4 px-3 mt-2"
            description="We believe in fostering strong partnerships with blockchain networks and projects"
          />
        </ValuesItemCard>
      </div>
      <div className="flex w-1/2 flex-col h-[100%]  md:gap-y-6 xs:gap-y-4 gap-y-2">
        <ValuesItemCard
          className="h-[48%]"
          gradient={"card-gradient-2"}
          title="Transparency"
        >
          <div className="mt-4 relative">
            <div className="lg:h-8 md:h-6 h-5 flex items-center justify-center gap-x-2 transition-all duration-[400ms] opacity-0 group-hover:opacity-100 ease-in-out group-hover:scale-x-100 scale-x-0 lg:w-48 sm:w-36 w-28   absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-20 rounded-full bg-gradient-to-r from-[#972002]  to-[#000]">
              <span className="lg:text-3xl md:text-2xl sm:text-xl text-base text-white pt-2 transition-all duration-250 opacity-0 scale-0 group-hover:opacity-100 group-hover:scale-100 delay-[300ms]">
                *
              </span>
              <span className="lg:text-3xl md:text-2xl sm:text-xl text-base text-white pt-2 transition-all duration-250 opacity-0 scale-0 group-hover:opacity-100 group-hover:scale-100 delay-[400ms]">
                *
              </span>
              <span className="lg:text-3xl md:text-2xl sm:text-xl text-base text-white pt-2 transition-all duration-250 opacity-0 scale-0 group-hover:opacity-100 group-hover:scale-100 delay-[500ms]">
                *
              </span>
              <span className="lg:text-3xl md:text-2xl sm:text-xl text-base text-white pt-2 transition-all duration-250 opacity-0 scale-0 group-hover:opacity-100 group-hover:scale-100 delay-[600ms]">
                *
              </span>
              <span className="lg:text-3xl md:text-2xl sm:text-xl text-base text-white pt-2 transition-all duration-250 opacity-0 scale-0 group-hover:opacity-100 group-hover:scale-100 delay-[700ms]">
                *
              </span>
            </div>
            <img
              className="transition-all duration-[300ms] ease group-hover:scale-[1.1] lg:h-auto  sm:h-28 h-20"
              style={{
                filter: "drop-shadow(0px 0px 8px rgba(0,0,0,0.14))",
              }}
              src={valuesTransparencyLogo}
            />
          </div>
          <TextCard
            className="text-gradient-1 lg:px-3 md:px-1 xl:mt-4 mt-3"
            description={
              "At Blazpay, we prioritize transparency in our processes, ensuring that users have clear insights into their transactions"
            }
          />
        </ValuesItemCard>
        <ValuesItemCard
          className="h-[52%] overflow-hidden"
          gradient={"bg-gradient-to-l from-[#ff3503] to-[#000]"}
          title="User-centric"
        >
          <div
            style={{
              perspective: "3000px",
              boxShadow:
                "12px 12px 13px rgba(0,0,0,0.24),-12px 12px 13px rgba(0,0,0,0.24),0 2px 2px rgba(0,0,0,0.1) inset",
            }}
            className="lg:m-8 md:mt-4 sm:mt-5 mt-3 xl:h-40 xl:w-44 lg:h-36 lg:w-40 sm:h-32 xs:h-24 h-20 sm:w-32 xs:w-28 w-24 relative lg:rounded-3xl sm:rounded-2xl rounded-xl border-l-2 group-hover:border-[#ff3503] group-hover:scale-[1.05] transition-all duration-[500ms] ease border-[#434343] border-r-2 "
          >
            <div className="absolute z-[-1]  top-1/2 -translate-y-1/2 h-[2px] lg:w-36 sm:w-28 w-24  bg-[#434343]  lg:group-hover:-left-36 md:group-hover:-left-28 sm:group-hover:-left-28 group-hover:-left-24  lg:-left-72 sm:-left-52 -left-44  transtion-left duration-[300ms] ease">
              <div className="absolute lg:h-6 lg:w-6 md:h-5 md:w-5 h-4 w-4  group-hover:left-1/2 -left-16  group-hover:-translate-x-1/2 lg:-top-[11px] md:-top-[10px] -top-[7px] border-2 border-[#434343]   delay-[500ms]  transtion-all duration-[400ms]  rounded-full bg-[#272525]"></div>
            </div>
            <div className="absolute z-20 sm:inset-4 inset-3 flex justify-center items-center sm:rounded-xl rounded-lg bg-[#272525]">
              <FaUser className="lg:text-6xl sm:text-3xl xs:text-2xl text-xl text-[#676767] transition-all duration-[500ms] ease group-hover:text-[#4b3f3d]  group-hover:scale-[1.15]" />
            </div>
          </div>
          <TextCard
            className={"text-gradient-2 lg:px-5 md:px-4 sm:mt-4 xs:mt-3 mt-4"}
            description={
              "Our platform is built with a focus on delivering a seamless and intuitive user experience, empowering both beginners and advanced users to navigate DeFi with ease"
            }
          />
        </ValuesItemCard>
      </div>
    </div>
  );
};

export default CardContainer;

const ValuesItemCard = ({ className, title, children, gradient }) => {
  return (
    <div
      role="card"
      className={`${className} z-10 group transition-all duration-[400ms] ease xs:hover:scale-[1.05] hover:scale-[1.02] relative w-full  bg-gradient-to-bl from-[#434343] to-[#000] lg:rounded-3xl rounded-2xl`}
    >
      <div
        className={`${gradient} absolute inset-[1px] bg-black rounded-[30px]`}
      >
        <div
          style={{
            backdropFilter: "blur(30px)",
          }}
          className="absolute inset-0 md:px-6 sm:px-4 px-2 md:py-5 sm:py-4 py-3 flex flex-col items-center lg:rounded-[22px] rounded-2xl bg-[#111]/90"
        >
          <h1 className="text-white font-redHat font-bold text-center gradient-heading lg:text-3xl md:text-2xl sm:text-xl text-base">
            {title}
          </h1>
          {children}
        </div>
      </div>
    </div>
  );
};
const TextCard = ({ className, description }) => {
  return (
    <p
      className={`${className} xl:text-xl lg:text-base  md:text-[13px] sm:text-xs text-[10px] sm:leading-[1.16] leading-[1.15] w-full xl:leading-[1.18] text-center`}
    >
      {description}
    </p>
  );
};
