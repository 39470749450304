import React, { useEffect, useState, useRef } from "react";
import "../../styles/error.css";
import blazIcon from "../../assets/blazIcon.png";
import "../../styles/common.css";
import "../../styles/aboutus.css";
import { gsap } from "gsap";
import { BsArrowDown } from "react-icons/bs";

const EntrypassHero = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [showGraphics, setShowGraphics] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowOverlay(true);
    }, 800);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowGraphics(true);
      window.scrollBy({
        top: 1000,
        left: 0,
        behavior: "smooth",
      });
    }, 3500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="text-white text-center xl:min-h-screen  md:h-[840px] sm:h-[760px] min-h-screen pb-12  flex flex-col items-center font-redHat md:pt-28 sm:pt-20 pt-28">
      <div
        className={`w-full flex flex-col items-center  transition-all duration-[500ms] ease
             ${
               showOverlay
                 ? "opacity-100 translate-y-0"
                 : "opacity-0 translate-y-[30vh]"
             }
        `}
      >
        <h1 className="lg:text-6xl md:text-5xl text-3xl max-ss:text-2xl md:font-semibold font-bold sm:px-0 xs:px-8 px-5">
          Explore Blazpay's Incredible
        </h1>
        <h1 className="lg:text-6xl md:text-5xl text-[#999999] text-3xl max-ss:text-2xl md:font-semibold font-bold">
          Entrypasses
        </h1>

        <p className="2xl:px-52 2xl:container lg:w-[70%] w-4/5 sm:text-xs text-[10px] xl:text-xl md:text-base  mt-4">
          Unlock exclusive experiences with blockchain-powered entry passes.
          Simplify access, ensure security, and elevate your event participation
          with our innovative crypto-based solutions
        </p>
      </div>
      <div
        className={`mt-12 relative group  transition-all duration-[500ms] ease
            
        `}
      >
        <Lines
          isActive={showGraphics}
          className="sm:top-32 top-36 md:left-[76px] sn:left-28 left-40"
        />
        <Lines
          isActive={showGraphics}
          className="sm:top-44 top-48 sm:left-24 left-48"
        />
        <Lines
          isActive={showGraphics}
          className="sm:top-52 top-48 sm:left-32 left-64"
        />
        <Lines
          isActive={showGraphics}
          className="md:top-16 top-40 md:right-16 sm:right-[56px] right-48"
        />
        <Lines
          isActive={showGraphics}
          className="sm:top-44 top-44 sm:right-24 right-[116px]"
        />
        <Lines
          isActive={showGraphics}
          className="sm:top-52 top-32 sm:right-32 right-[172px]"
        />
        <Sector
          className={`absolute left-1/2 -translate-x-1/2 bottom-0 
            ${showGraphics ? "scale-100 opacity-100" : "scale-100 opacity-0 "}
          `}
        />

        <div className="absolute z-[-1] left-1/2 -translate-x-1/2 md:top-10 sm:top-16 top-24 md:w-[480px] sm:w-[420px] sm:w-[360px] w-[280px] md:h-[220px] sm:h-[180px] sm:h-[160px] h-[120px] rounded-[50%] border border-[#601401] " />
        <div
          className={`${
            showGraphics ? "scale-100 opacity-100" : "scale-100 opacity-0 "
          } absolute md:top-20 sm:top-24 top-28 left-1/2 -translate-x-1/2 md:h-36 sm:h-28 h-20 md:w-36 sm:w-28 w-20 rounded-full border border-[#8E2105] transition-all duration-[400ms] ease`}
        >
          <div className="absolute rotate-positive sm:inset-2 inset-1 rounded-full border border-[#5D1503]">
            <span className="block absolute top-0  h-[3px] w-[3px] rounded-full bg-[#ff3503]"></span>
            <span className="block absolute top-0 right-0 h-[3px] w-[3px] rounded-full bg-[#ff3503]"></span>
            <span className="block absolute -left-4 top-1/2 -translate-y-1/2 h-[3px] w-[3px] rounded-full bg-[#ff3503]"></span>
            <span className="block absolute bottom-3 right-0 h-[3px] w-[3px] rounded-full bg-[#ff3503]"></span>
            <span className="block absolute right-0 top-1/2 h-[3px] w-[3px] rounded-full bg-[#ff3503]"></span>
            <div
              style={{
                boxShadow: "12px 12px 20px rgba(0,0,0,0.2) inset",
              }}
              className="absolute sm:inset-3 inset-1 flex justify-center items-center rounded-full group hover:scale-[0.90] transition-all duration-[400ms] bg-[#ff3503]"
            ></div>
          </div>
          <img
            style={{
              filter: "drop-shadow(0 0 10px rgba(0,0,0,0.8))",
            }}
            className="absolute sm:scale-100 scale-[0.75] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            src={blazIcon}
          />
        </div>
        <AnimatedSVG
          className={"  h-auto md:scale-100 sm:scale-[0.80] scale-[0.60]"}
        />
        <div
          className={`
          
          ${showGraphics ? "scale-100 opacity-100" : "scale-100 opacity-0 "}
          absolute md:-bottom-40 -bottom-36 md:group-hover:-bottom-44 group-hover:-bottom-40  left-1/2 -translate-x-1/2 flex justif-center transition-all duration-250 ease`}
        >
          <BsArrowDown className=" md:text-9xl text-8xl text-[#ff3503] z-10" />
          <BsArrowDown className=" text-3xl text-[#701600] z-[-1] absolute left-1/2 -translate-x-1/2 -bottom-1" />
          <span className="block absolute animate-pulse top-1/2 -translate-y-1/2 left-12 h-[3px] w-[3px] rounded-full bg-[#ff3503]"></span>
          <span className="block absolute animate-pulse -bottom-2 left-12 h-[3px] w-[3px] rounded-full bg-[#ff3503]"></span>
          <span className="block absolute animate-pulse bottom-0 right-12 h-[3px] w-[3px] rounded-full bg-[#ff3503]"></span>
        </div>
      </div>
    </div>
  );
};
export default EntrypassHero;

const AnimatedSVG = ({ className }) => {
  const pathRef = useRef(null);
  useEffect(() => {
    if (pathRef.current) {
      const pathLength = pathRef.current.getTotalLength();

      gsap.set(pathRef.current, {
        strokeDasharray: pathLength,
        strokeDashoffset: pathLength,
      });

      gsap.to(pathRef.current, {
        strokeDashoffset: 0,
        duration: 3,
        ease: "power2.out",
      });
    }
  }, []);

  return (
    <div className="block flex justify-center items-center">
      <svg
        className={`${className} `}
        width="574"
        height="305"
        viewBox="0 0 574 305"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className=""
          ref={pathRef}
          d="M327.477 1.31462C361.621 6.34288 344.654 57.6492 373.342 76.8296C416.844 105.915 499.435 10.9838 506.62 62.8054C510.465 90.538 470.19 100.9 476.943 128.072C486.213 165.378 559.48 121.012 570.831 157.739C582.403 195.18 545.747 237.305 506.62 234.872C467.049 232.411 479.101 125.914 453.201 148.569C427.301 171.224 469.427 286.405 427.301 288.272C385.174 290.139 393.273 208.585 351.219 211.678C307.794 214.872 332.058 300.806 288.627 303.914C241.05 307.319 266.47 208.45 219.02 203.587C171.428 198.71 163.611 322.818 130.528 288.272C107.65 264.382 148.085 231.617 130.528 203.587C103.637 160.654 -0.921424 246.112 1.02687 195.496C2.48965 157.493 75.7965 172.028 76.5691 134.005C77.1844 103.719 28.3873 101.64 30.7042 71.4357C34.2305 25.4627 114.759 130.006 146.176 96.2478C167.45 73.3877 134.125 38.815 156.428 16.957C190.978 -16.9034 218.426 90.3395 264.885 76.8296C301.672 66.1322 289.574 -4.26717 327.477 1.31462Z"
          stroke="#FF3503"
          strokeWidth="2"
          fill="none"
        />
      </svg>
    </div>
  );
};

const Sector = ({ className }) => {
  return (
    <div
      className={`${className} xl:h-12 xl:w-12 h-10 w-10 flex items-center justify-center `}
    >
      <div className="absolute  top-0 left-0 h-full w-full  sector-rotation rounded-full border-4 border-t-[#ff3503] border-l-transparent border-b-transparent border-r-transparent" />
      <span className="h-1 w-1 absolute top-0 left-0 rounded-full bg-[#ff5500]" />
      <div className="xl:h-8 xl:w-8 h-6 w-6  rounded-full sector-bg flex justify-center items-center">
        <span className="xl:h-4 xl:w-4 h-2 w-2 rounded-full bg-[#ff5500]" />
      </div>
    </div>
  );
};

const Lines = ({ className, isActive }) => {
  return (
    <div
      className={`${className} ${
        isActive ? "opacity-100 scale-100:" : "opacity-0 scale-0"
      } absolute transition-all duration-250 ease h-64 w-[2px] bg-gradient-to-b from-[#ff3503] to-[#000]`}
    />
  );
};
