import React from "react";

const CopyRight = () => {
  return (
    <div>
      <h1 className="text-[#939393] font-semibold font-redHat text-center 2xl:pt-20 xl:pt-16 pt-10 pb-7">
        Copyright &copy; Blazpaylabs
      </h1>
    </div>
  );
};

export default CopyRight;
