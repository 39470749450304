import React from "react";

const ButtonCard = ({ title, handleClick }) => {
  return (
    <button
      className="border-2 flex items-center justify-center lg:w-[351px] lg:h-[70px] sm:p-4 sm:w-[300px] sm:h-[50px] w-40 h-14  btn font-RedHat lg:text-2xl sm:text-[16px] text-[.75rem] leading-9 text-[#FFFFFF]"
      onClick={handleClick}
    >
      {title}
    </button>
  );
};

export default ButtonCard;
