import React from "react";
import vector from "../../assets/supportArrowTop.svg";
const TextCard = ({ title }) => {
  return (
    <h1 className="lg:text-2xl font-bold font-RedHat flex items-center   lg:gap-8 sm:gap-2 my-12">
      {title}
      <span>
        <img src={vector} alt={vector} />
      </span>
    </h1>
  );
};

export default TextCard;
