import "../../styles/aboutus.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import member1LogoUrl from "../../assets/team/baldeep.png";
import member2LogoUrl from "../../assets/team/bhatti.png";
import member3LogoUrl from "../../assets/team/bisht.png";
const TeamMember = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  const team = [
    {
      role: "CEO & Co-founder",
      name: "Baldeep Singh Sethi",
      order: 1,
      className: "bg-gradient-to-b from-[#601907] to-[#0d0d0d]",
      dataAos: "fade-right",
      delay: "300",
      logoUrl: member1LogoUrl,
    },
    {
      role: "CTO & Co-founder",
      name: "Subham Bisht",
      order: 2,
      className:
        "bg-gradient-to-b from-[#601907] to-[#0d0d0d] relative xl:top-20 lg:top-16 md:top-12",
      dataAos: "fade-up",
      delay: "600",
      logoUrl: member3LogoUrl,
    },
    {
      role: "COO & Co-founder",
      name: "Rishab Singh Bhatti",
      order: 3,
      className: "bg-gradient-to-b from-[#601907] to-[#0d0d0d]",
      dataAos: "fade-left",
      delay: "900",
      logoUrl: member2LogoUrl,
    },
  ];
  return (
    <div className="xl:max-h-screen xl:min-h-[1000px]  overflow-x-hidden lg:h-[900px] md:h-[800px] w-full pt-32 pb-28 ">
      <div className="lg:container w-full  lg:px-0 px-8 mx-auto flex flex-col items-center">
        <h1
          data-aos="fade-up"
          data-aos-duration="800"
          className="text-white g:text-[54px] md:text-5xl sm:text-4xl xs:text-3xl text-2xl gradient-heading font-bold text-center font-redHat"
        >
          Team of Creators
        </h1>
        <p
          data-aos="fade-up"
          data-aos-duration="1000"
          className="md:text-xl xs:text-base ss:text-[14px] text-xs md:w-3/5 xs:w-4/5 w-[90%] text-center sm:mt-4 mt-2 text-white "
        >
          We're dedicated to building seamless, intelligent, and user-friendly
          solutions that empower users to effortlessly navigate the future of
          crypto.
        </p>
        <div className="w-full flex md:flex-row flex-col md:gap-y-0 gap-y-3 md:items-start items-center xl:justify-between justify-center xl:gap-x-0 lg:gap-x-5 gap-x-3 2xl:px-44 xl:px-20 lg:px-7   md:mt-20 sm:mt-16 xs:mt-12 mt-8">
          {team?.map((member, index) => {
            return (
              <TeamCard
                key={index}
                name={member.name}
                role={member.role}
                order={member.order}
                className={`${member.className}`}
                dataAos={member.dataAos}
                delay={member.delay}
                logoUrl={member.logoUrl}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TeamMember;

const TeamCard = ({
  className,
  name,
  role,
  order,
  dataAos,
  delay,
  logoUrl,
}) => {
  return (
    <div
      data-aos={dataAos}
      duration="600"
      data-aos-delay={delay}
      className={`${className} xl:h-[440px] xl:w-[360px] lg:h-[340px] md:h-[300px] ss:h-[360px] h-[340px] lg:w-[280px] md:w-[250px] ss:w-[300px] w-[280px]  relative border-2 border-[#434343] md:rounded-3xl rounded-[20px]`}
    >
      <div
        className={`absolute lg:h-7 h-5 lg:w-7 w-5 bg-[#555] lg:border-4 border-2 border-[#000] rounded-full ${
          order === 1 && "xl:top-10 lg:top-7 top-5 xl:left-9 lg:left-7 left-5"
        } ${
          order === 2 &&
          "xl:top-10 lg:top-7 md:top-5 bottom-5 xl:right-9 lg:right-7 right-5"
        }
         ${
           order === 3 &&
           "xl:bottom-10 lg:bottom-7 md:bottom-5 md:mt-0 mt-5 md:ml-0 ml-5  xl:right-9 lg:right-7 md:right-5 "
         } `}
      >
        <div className="absolute inset-[2px] rounded-full border-2 border-[#000] bg-[#ff3503]"></div>
      </div>
      <div
        className={`absolute  
        ${
          order === 1 &&
          "xl:w-36 lg:w-32 w-20 lg:h-[6px] h-1 xl:top-[50px] lg:top-[39px] top-[27px] xl:-left-[104px] lg:-left-24 -left-[57px] lg:border-2 border border-[#000] bg-gradient-to-r from-[#000] rounded-full to-[#999999]"
        }
             ${
               order === 2 &&
               "xl:h-36 lg:h-32 md:h-20 w-20 lg:w-[6px] md:w-1 h-1 xl:-top-[100px] lg:-top-24 md:-top-[57.5px] bottom-[29px] xl:right-[47px] lg:right-[39px] md:right-[27.5px] -right-[58px] lg:border-2 border border-[#000] md:bg-gradient-to-b bg-gradient-to-l from-[#000] rounded-full to-[#999999]"
             }
        ${
          order === 3 &&
          "xl:w-36 lg:w-32 w-20 lg:h-[6px] h-1 xl:bottom-[51px] lg:bottom-10 md:bottom-[29px]   xl:-right-[104px] lg:-right-24 md:-right-[58px] md:ml-0 md:mt-0 -ml-[56px] mt-7  lg:border-2 border border-[#000] md:bg-gradient-to-l bg-gradient-to-r from-[#000] rounded-full to-[#999999]"
        }
        `}
      />
      <div className="flex absolute xl:left-9 xl:bottom-10 left-7 bottom-6 flex-col items-start">
        <h1 className="xl:text-2xl lg:text-xl text-base font-bold text-white">
          {name}
        </h1>
        <p className="xl:text-base lg:text-sm text-xs font-bold text-[#ff3503]">
          {role}
        </p>
      </div>
      <img
        style={{
          filter: "drop-shadow(0 0 20px rgba(0,0,0,0.8))",
        }}
        src={logoUrl}
        className="h-full w-full absolute z-[-1] object-cover md:rounded-3xl rounded-[20px] "
      />
    </div>
  );
};
