import React from "react";
import heroImg from "../../assets/heroImg.png";
import CountUp from "react-countup";
import "../../styles/aboutus.css";

const stats = [
  { name: "Active Users", value: "800K+" },
  { name: "Total Transactions", value: "3M+" },
  { name: "Rewards Distributed", value: "$200K+" },
  { name: "Entrypass Minted", value: "1M+" },
];
const Hero = () => {
  const handleButtonClick = () => {
    window.open("https://rewards.blazpay.com", "_blank");
  };
  return (
    <div className="lg:pb-40 pb-8 relative w-full">
      <div
        style={{
          backdropFilter: "blur(40px)",
        }}
        className="w-full z-20  relative bg-[#0a0a0a]/80 "
      >
        <img
          className="absolute top-1/2 -translate-y-1/2 right-0 xl:h-[74%] lg:h-[70%] md:h-[62%] h-[67vw] "
          src={heroImg}
          alt="heroImg"
        />
        <div className="md:container w-full md:px-0 xs:px-8 relative min-h-screen h-[760px] mx-auto xl:pt-28 lg:pt-20 md:pt-20 pt-32 ">
          <div className="text-white font-redHat lg:pt-28 md:pt-24 sm:w-11/12 mx-auto xs:px-0 px-4 ">
            <div className="gradient-label max-sm:text-center xl:text-8xl lg:text-7xl xs:text-6xl text-5xl font-semibold">
              <h1 className="">Seamless Crypto</h1>
              <h1 className="">Solutions</h1>
            </div>
            <p className="xl:text-xl lg:text-base md:text-sm  lg:leading-5 sm:pb-20 pb-12 mt-4 xl:w-7/12 lg:w-8/12 md:w-9/12 w-11/12 max-sm:mx-auto max-sm:text-center ">
              At Blazpay, we're dedicated to innovating in the field of
              crypto-financial solutions with a comprehensive suite of products.
              Our vision is to offer a seamless, one-stop experience for users
              navigating the complex world of cryptocurrency.{" "}
            </p>

            <button
              onClick={handleButtonClick}
              className="py-2 px-[30px] sm:hidden block relative left-1/2 -translate-x-1/2 transition-all duration-250 active:scale-[.96] rounded-full text-sm font-medium bg-[#1d1d1d] text-[#ff3503] border border-[#ff3503]"
            >
              Launch Dashboard
            </button>
          </div>
          <div className=" absolute sm:bottom-16 ss:bottom-20 bottom-10  flex-wrap   2xl:px-32 xl:px-24 md:px-16 xs:px-8 px-4  left-1/2 -translate-x-1/2 w-full flex items-center xs:justify-between ss:gap-x-8 gap-x-2 xs:gap-y-0 gap-4  justify-center  text-white">
            {stats?.map((item, index) => {
              return (
                <DataCard key={index} name={item.name} value={item.value} />
              );
            })}
          </div>
        </div>
      </div>

      <div className=" xl:h-[900px] md:block hidden  xl:w-[900px] lg:h-[600px] lg:w-[600px] sm:h-[560px] sm:w-[560px]  xs:h-[440px] h-[320px]  xs:w-[440px] w-[320px] absolute left-1/2 -translate-x-1/2 xl:-bottom-12 lg:bottom-0 sm:-bottom-24 -bottom-16 ">
        <div
          style={{
            filter: "blur(100px)",
          }}
          className="xl:h-[400px] xl:w-[400px]  sm:h-[320px] xs:h-[280px] h-[160px] sm:w-[320px] xs:w-[280px] w-[160px]  absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-[#ff3503]"
        />
        <div className="xl:h-[900px]  xl:w-[900px] lg:h-[600px] lg:w-[600px] sm:h-[560px] sm:w-[560px] xs:h-[440px] h-[320px] xs:w-[440px] w-[320px] rotate-positive  relative  outer-circle flex justify-center items-center">
          <Sector className={"absolute -bottom-6"} />
          <Sector className={"absolute  -top-6"} />
          <Sector className={"absolute top-1/2 -translate-y-1/2 -right-6"} />
          <Sector className={"absolute top-1/2 -translate-y-1/2 -left-6"} />
          <div className="h-[92%] w-[92%]  rotate-negative rounded-full inner-circle" />
        </div>
      </div>
    </div>
  );
};

export default Hero;

const DataCard = ({ name, value }) => {
  const numericValue = parseFloat(value.replace(/[^0-9.]/g, ""));
  const isDollar = value.includes("$");
  return (
    <div className="flex flex-col lg:gap-y-3 gap-y-1 font-redHat">
      <p className="xl:text-xl md:text-[14px] xs:text-xs text-[10px] font-medium">
        {name}
      </p>
      <h1 className="xl:text-[40px] xs:text-3xl text-2xl font-bold text-center">
        {isDollar && "$"}
        <CountUp start={0} end={numericValue} duration={2} separator="," />
        {!isDollar &&
          (value.includes("K") ? "K" : value.includes("M") ? "M" : "")}
        +
      </h1>
    </div>
  );
};

const Sector = ({ className }) => {
  return (
    <div
      className={`${className} xl:h-12 xl:w-12 sm:h-10 h-7 sm:w-10 w-7 flex items-center justify-center `}
    >
      <div className="absolute  top-0 left-0 h-full w-full  sector-rotation rounded-full md:border-4 border-2 border-t-[#ff3503] border-l-transparent border-b-transparent border-r-transparent" />
      <span className="h-1 w-1 absolute top-0 left-0 rounded-full bg-[#ff5500]" />
      <div className="xl:h-8 xl:w-8 sm:h-6 h-5 sm:w-6  w-5 rounded-full sector-bg flex justify-center items-center">
        <span className="xl:h-4 xl:w-4 h-2 w-2 rounded-full bg-[#ff5500]" />
      </div>
    </div>
  );
};
