import React from "react";
import EntrypassHero from "../components/entrypass/EntrypassHero";
import Slider from "../components/entrypass/Slider";
import entrypassHeroDotsUrl from "../assets/entrypassDotsBg.png";
const Entrypasspage = () => {
  return (
    <div className="flex flex-col items-center w-full min-h-screen overflow-x-hidden  relative">
      <div
        style={{
          filter: "blur(100px)",
        }}
        className="xl:w-[900px] w-[600px] xl:h-[400px] h-[240px] max-lg:w-full max-sm:w-full absolute left-1/2 -translate-x-1/2 xl:top-[-200px] top-[-100px] bg-[#ff3503] z-0 rounded-full"
      />
      <div
        style={{
          filter: "blur(100px)",
        }}
        className="absolute  left-1/2 -translate-x-1/2 rotate-[-45deg] top-96 h-32 w-96 bg-[#ff3503] rounded-full"
      />
      <div
        style={{
          backdropFilter: "blur(40px)",
        }}
        className="h-full w-full bg-[#0a0a0a]/60"
      >
        <img
          src={entrypassHeroDotsUrl}
          className="z-[-1] absolute  lg:left-0 -left-16 top-96"
        />
        <img
          src={entrypassHeroDotsUrl}
          className="z-[-1] absolute  lg:right-0 -right-16 bottom-96"
        />
        <EntrypassHero />
        <Slider />
      </div>
    </div>
  );
};

export default Entrypasspage;
