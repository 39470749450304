import React, { useEffect } from "react";
import CardContainer from "./CardContainer";
import "../../../styles/common.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Values = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div className="container mx-auto flex flex-col justify-center items-center xl:py-40 py-20">
      <h1
        data-aos="fade-up"
        data-aos-duration="800"
        className="text-white g:text-[54px] md:text-5xl sm:text-4xl xs:text-3xl text-2xl gradient-heading font-bold text-center font-redHat"
      >
        Our Values
      </h1>
      <CardContainer />
    </div>
  );
};

export default Values;
