import React from "react";
import blazlogo from "../../assets/logo.svg";
import { useNavigate } from "react-router-dom";
import CopyRight from "./CopyRight";

const AboutUsFooter = () => {
  const navigate = useNavigate();
  const ecosystem = [
    {
      value: "Game",
      url: "https://www.rewards.blazpay.com/gaming-marketplace",
      newTab: true,
    },
    {
      value: "BlazAI",
      url: "https://ai.blazpay.com/",
      newTab: true,
    },
    {
      value: "NFT",
      url: "/entrypass",
      newTab: false,
    },
  ];

  const resources = [
    {
      value: "Dashboard",
      url: "https://www.rewards.blazpay.com",
      newTab: true,
    },
    {
      value: "Defi App",
      url: "https://www.defi.blazpay.com",
      newTab: true,
    },
    {
      value: "Blogs",
      url: "https://medium.com/@blazpay",
      newTab: true,
    },
  ];

  const community = [
    {
      value: "Twitter",
      url: "https://x.com/blazpaylabs",
      newTab: true,
    },
    {
      value: "Instagram",
      url: "https://www.instagram.com/blazpay",
      newTab: true,
    },
    {
      value: "Telegram",
      url: "https://t.me/blazpay",
      newTab: true,
    },
    {
      value: "Discord",
      url: "https://discord.gg/mUCKPA86eC",
      newTab: true,
    },
    {
      value: "LinkedIn",
      url: "https://www.linkedin.com/company/blazpay/",
      newTab: true,
    },
  ];

  const support = [
    {
      value: "Terms of Services",
      url: "/privacy",
      newTab: false,
    },
    {
      value: "Privacy Policy",
      url: "/privacy",
      newTab: false,
    },
    {
      value: "Contact",
      url: "/support",
      newTab: false,
    },
    {
      value: "Github",
      url: "https://github.com/blazpay",
      newTab: true,
    },
    {
      value: "Brand Assets",
      url: "https://linktr.ee/Blazpay",
      newTab: true,
    },
  ];
  const handleLinkClick = (link, newTab) => {
    if (newTab) {
      window.open(link, "_blank");
    } else {
      navigate(link);
    }
  };
  return (
    <div className="w-full h-fit 2xl:pt-16 md:pt-12 pt-8 flex flex-col items-center bg-[#0b0b0b]">
      <div className="container w-10/12 mx-auto p-2 md:p-6 lg:p-8 flex flex-col gap-y-14 lg:flex-row lg:justify-between text-white font-redHat ">
        <div className=" lg:w-5/12 flex justify-between">
          <div className="">
            <img className="h-6 md:h-auto" src={blazlogo} alt="logo" />
            <div className="flex flex-col gap-y-3 mt-6 md:mt-8">
              <FooterButton
                link={"https://www.defi.blazpay.com"}
                text={"Launch App"}
                className={`text-xs md:text-base 2xl:text-xl font-bold font-redHat text-[#7A7A7A] px-5 py-2 md:px-8 md:py-3 rounded-full bg-[#1D1D1D]`}
              />
              <FooterButton
                link={"https://www.rewards.blazpay.com"}
                text={"Earn Rewards"}
                className={`text-xs md:text-base 2xl:text-xl font-bold font-redHat border-[#FF5500] border text-[#7A7A7A] px-5 py-2 md:px-8 md:py-3 rounded-full `}
              />
            </div>
          </div>
          <FooterCols
            list={ecosystem}
            handleClick={handleLinkClick}
            title="Ecosystem"
          />
        </div>

        <div className="lg:w-6/12 flex justify-between">
          <FooterCols
            list={resources}
            logo={true}
            handleClick={handleLinkClick}
            title="Resources"
          />
          <FooterCols
            list={community}
            handleClick={handleLinkClick}
            title="Community"
          />
          <FooterCols
            list={support}
            handleClick={handleLinkClick}
            title="Support"
          />
        </div>
      </div>
      <CopyRight />
    </div>
  );
};

export default AboutUsFooter;

const FooterCols = ({ title, list, handleClick, logo = false }) => {
  return (
    <div>
      <h1 className="text-sm md:text-xl 2xl:text-2xl font-semibold ">
        {title}
      </h1>
      <ul className="mt-3 md:mt-6 font-semibold text-[#666666] text-xs md:text-base 2xl:text-xl">
        {list?.map((items) => (
          <li
            onClick={() => handleClick(items?.url, items?.newTab)}
            role="button"
            className="mb-2 flex items-center gap-x-2 md:mb-3"
          >
            {items.value}
            {logo && items?.logo}
          </li>
        ))}
      </ul>
    </div>
  );
};

const FooterButton = ({ text, className, link }) => {
  const handleButtonClick = () => {
    window.open(link, "_blank");
  };
  return (
    <button onClick={handleButtonClick} className={className}>
      {text}
    </button>
  );
};
