import "../../styles/aboutus.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useRef } from "react";
import cryptoAppVideoUrl from "../../assets/media/cryptoAppVideo.mp4";
import cryptoAppVideoUrlWebM from "../../assets/media/cryptoAppVideo.webm";
const CryptoApp = () => {
  const videoRef = useRef(null);
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 2;
    }
  }, []);
  const handleButtonClick = () => {
    window.open("https://defi.blazpay.com", "_blank");
  };
  return (
    <div className="xl:max-h-screen xl:min-h-[1100px] relative w-full overflow-hidden py-16">
      <div className="h-[400px] md:block hidden absolute top-1/2 -translate-y-1/2 -left-[200px] w-[400px] rounded-full bg-gradient-to-l from-[#111] via-[#000]  to-[#000] p-7">
        <div className="absolute inset-2 bg-[#000] rounded-full">
          <div className="absolute inset-5 bg-gradient-to-l from-[#390d02] via-[#000] to-[#000]  rounded-full">
            <div className="absolute inset-2 bg-[#000] rounded-full" />
          </div>
        </div>
      </div>
      <div className="flex flex-col z-20 items-center h-full container mx-auto">
        <div className="flex flex-col md:gap-y-5 sm:gap-y-4 xs:gap-y-3 gap-y-2 items-center w-full">
          <h1
            data-aos="fade-up"
            data-aos-duration="800"
            className="md:px-0 ss:px-6 text-white lg:text-[54px] md:text-5xl sm:text-4xl xs:text-3xl text-2xl lg:leading-[1.15] lg:w-3/5 gradient-heading font-bold text-center font-redHat"
          >
            Download the AI Crypto Application of the future
          </h1>
          <p
            data-aos="fade-up"
            data-aos-duration="1000"
            className="md:text-xl xs:text-base ss:text-[14px] text-xs text-white "
          >
            Experience the Next Generation of Crypto Solutions
          </p>
          <div className="relative w-full  flex justify-center items-center">
            <div className="md:h-[600px] xs:h-[400px] h-[240px] md:w-[600px] xs:w-[400px] w-[240px] absolute md:-top-32 xs:-top-12 -top-6 border border-[#373737]/50 z-[-1] rounded-full md:p-24 xs:p-16 p-8">
              <div className="h-full w-full rounded-full border border-[#373737]/50" />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="1200"
              className={`xl:w-[1000px] xl:h-[600px] lg:w-[900px] lg:h-[530px] md:w-[700px] md:h-[415px] xs:w-[580px] w-[380px] xs:h-[340px] h-[230px] z-30 transition-all duration-[340ms] hover:border-2 mt-8 bg-[#0b0b0b] 
              md:rounded-[45px] xs:rounded-3xl rounded-[14px] xl:p-8 lg:p-6  xs:p-4 p-2 border border-[#434343]/50  `}
            >
              <div className="crypto-app-gradient z-10 md:rounded-[36px] rounded-2xl h-full w-full">
                <div className="md:rounded-[30px] rounded-2xl bg-[#0b0b0b] absolute lg:inset-3 md:inset-2 inset-[6px] z-50">
                  <video
                    ref={videoRef}
                    className="h-full xl:w-full lg:rounded-3xl md:rounded-[30px] rounded-xl object-cover"
                    autoPlay
                    playsInline
                    preload="auto"
                    loop={true}
                    muted
                  >
                    <source src={cryptoAppVideoUrl} type="video/mp4" />
                    <source src={cryptoAppVideoUrlWebM} type="video/webm" />
                  </video>
                </div>
              </div>
            </div>
          </div>
          <p
            data-aos="fade-up"
            data-aos-duration="800"
            className="md:text-xl xs:text-base ss:text-[14px] text-xs text-center md:px-0 px-8 text-white md:mt-10 sm:mt-8 mt-6"
          >
            Why late experience the Worlds Fastest and Easiest AI Crypto
            Solution.
          </p>
          <button
            onClick={handleButtonClick}
            data-aos="fade-up"
            data-aos-duration="700"
            className="mt-2 lg:py-3 md:py-[10px] xs:py-2 py-[6px] lg:px-[56px] md:px-12 xs:px-10 px-8 transition-all duration-250 hover:opacity-80 active:scale-[.96] rounded-full xl:text-xl lg:text-base text-[13px] font-medium bg-[#1d1d1d] text-[#ff3503] border border-[#ff3503]"
          >
            Launch App
          </button>
        </div>
      </div>
    </div>
  );
};
export default CryptoApp;
