import "../styles/error.css";
import ErrorInfo from "../components/errorPage/ErrorInfo";
import TextInfo from "../components/errorPage/TextInfo";
import HomeButton from "../components/errorPage/HomeButton";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className="conatiner mx-auto error-background-image flex justify-center items-center relative overflow-hidden">
    <div className="">
      <div className="relative max-h-[800px] max-w-[800px] lg:h-[95vh] lg:w-[95vh] h-[70vw] w-[70vw] p-[2px] rounded-full bg-gradient-to-b from-black via-[#767676] to-black flex justify-center items-center ">
        <div className="bg-black  rounded-full max-h-[800px] max-w-[800px] lg:h-[95vh] p-[2px] lg:w-[95vh] h-[70vw] w-[70vw] flex justify-center items-center">
          <div className="max-h-[600px] max-w-[600px] lg:h-[75vh] lg:w-[75vh] h-[50vw] w-[50vw] relative  p-[2px] rounded-full bg-gradient-to-b from-black via-[#767676] to-black flex justify-center items-center">
            <div className="bg-black  rotate-border rounded-full max-h-[600px] max-w-[600px] lg:h-[75vh] lg:w-[75vh] h-[50vw] w-[50vw] p-[2px] flex justify-center items-center"></div>
            <div className="max-h-[400px] max-w-[400px] 2xl:h-[55vh] lg:h-[48vh] absolute p-[2px] 2xl:w-[55vh] lg:w-[48vh] h-[30vw] w-[30vw] rounded-full shadow-[0_0_45px_3px_#434343] flex justify-center items-center">
              <ErrorInfo />
            </div>
          </div>
        </div>
        <div className="absolute xl:bottom-[15%] lg:bottom-[15%] md:bottom-[15%] bottom-0 h-20 text-white font-redHat flex flex-col justify-center bg-black z-50">
          <TextInfo
            className={
              "2xl:text-5xl xl:text-5xl lg:text-5xl md:text-3xl font-medium text-center mb-3 flex items-center justify-center"
            }
            text={"Page not found"}
          />
          <div className="relative border border-[#434343] xl:px-16 xl:py-6 lg:px-16 lg:py-6 md:px-14 md:py-4 px-4 py-2 rounded-lg mb-3">
            <TextInfo
              className={"2xl:text-2xl xl:text-2xl lg:text-2xl md:text-base text-[10px] font-semibold"}
              text={"The Page you are searching for is not available"}
            />
            <div className="absolute h-[3px] w-1/12 rounded-full bg-[#FF5500] -top-[1px] left-10 translation-forward"/>
            <div className="absolute h-[3px] w-1/12 rounded-full bg-[#FF5500] -bottom-[2px] right-14 translation-backward"/>
          </div>
          <div className="flex flex-col items-center justify-center ">
            <HomeButton className={"max-sm:text-xs"} handleClick={() => navigate("/")} />
          </div>
        </div>
      </div>
      <div className="absolute w-14 h-14 rounded-full gradient-bg left-[3%] top-[35%] flex items-center justify-center animate-pulse">
        <div className="w-4 h-4 rounded-full bg-[#FF5500] "/>
      </div>
      <div className="absolute w-10 h-10 rounded-full gradient-bg right-[14%] top-[25%] flex items-center justify-center animate-pulse">
        <div className="w-3 h-3 rounded-full bg-[#FF5500] "/>
      </div>
      <div className="absolute w-8 h-8 rounded-full gradient-bg top-[4%] left-[26%] flex items-center justify-center animate-pulse">
        <div className="w-2 h-2 rounded-full bg-[#FF5500] "/>
      </div>
      <div className="absolute w-12 h-12 rounded-full gradient-bg bottom-[5%] right-[25%] flex items-center justify-center animate-pulse">
        <div className="w-4 h-4 rounded-full bg-[#FF5500] "/>
      </div>
    </div>
    </div>
  );
};

export default ErrorPage;
