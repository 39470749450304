import "../../styles/buttons.css";
const HomeButton = ({ className, handleClick, title }) => {
  return (
    <div className={`${className}`}>
      <button
        onClick={handleClick}
        className={`ue_neon_btn font-redHat font-bold lg:text-[14px] text-xs  xl:px-9 sm:px-7 px-6  lg:py-3 py-2 active:scale-[.96]`}
      >
        {title}
      </button>
    </div>
  );
};

export default HomeButton;
