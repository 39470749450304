import React, { useEffect } from "react";
import { jsPDF } from "jspdf";
import { useLocation } from "react-router-dom";
const data = {};
const policies = [
  "We prioritize your privacy and the security of your personal information.",
  "We have designed BlazPay to give you control over your data and to provide a transparent overview of how we handle your information.",
  "Do not collect your private keys.",
  "Do not sell your personal information.",
  "Do not collect or retain personal information unless necessary to provide you with the Services and a great user experience.",
  "May temporarily process your IP address to provide optimal performance and prevent security threats, such as DDoS attacks.",
  "Do not collect financial payment or banking information directly; however, third-party services may require you to submit such information for certain transactions.",
];
const personalInformationYouProvideToUs = [
  {
    title: "Account & Profile Information",
    value:
      "When creating an account or profile, you may provide your name, email address, and other necessary details.",
  },
  {
    title: "Contact Information",
    value:
      "You can provide additional information during interactions with us, such as through emails or other communications.",
  },
  {
    title: "Support Information",
    value:
      "When requesting support, we may collect personal information necessary to address your queries.",
  },
  {
    title: "Financial Information",
    value:
      "While we do not collect payment information directly, third-party services may collect this information for processing transactions.",
  },
];
const informationCollectedAutomatically = [
  {
    title: "Device and Usage Information",
    value:
      "We may collect data such as IP addresses, device identifiers, and interactions with the Services to enhance user experience and security.",
  },
  {
    title: "Cookies and Similar Technologies",
    value:
      "We use cookies and similar technologies to collect information automatically, which helps improve the functionality and performance of the Services.",
  },
];
const howWeUsePersonalInformationWeCollect = [
  {
    title: "To Provide Our Services",
    value:
      "Your information is necessary for the basic functionality of the Services and to address your requests.",
  },
  {
    title: "For Personalization",
    value:
      "To customize your experience based on your preferences and settings.",
  },
  {
    title: "For Product Improvement",
    value: "To develop and enhance our Services and features.",
  },
  {
    title: "To Communicate with You",
    value:
      "To provide customer support, send updates, and respond to inquiries.",
  },
  {
    title: "For Marketing Purposes",
    value: "Subject to consent, to send promotional materials and updates.",
  },
  {
    title: "For Security and Compliance",
    value:
      "To protect the integrity of our Services and comply with legal obligations.",
  },
];
const whoWeSharePersonalInformationWeCollect = [
  {
    title: "Service Providers",
    value:
      "We work with third parties to support our Services, such as hosting and network management.",
  },
  {
    title: "Legal Requirements",
    value:
      "We may disclose information to comply with legal obligations or to protect the rights and safety of BlazPay and our users.",
  },
  {
    title: "Business Partners",
    value:
      "With your consent, we may share information with partners for marketing purposes.",
  },
];
const PrivacyPolicy = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const handleDownload = () => {
    const doc = new jsPDF();
    let content = "";
    Object.entries(data).forEach(([key, value]) => {
      content += `${key}: ${value}\n`;
    });

    doc.text(content, 10, 20);
    doc.save("blazpay-privacy-policies.pdf");
  };
  return (
    <section className="min-h-screen xl:container w-full mx-auto lg:px-16  md:px-12 xs:px-7 px-6 xl:pt-20 sm:pt-16 pt-10 xl:pb-12 pb-10 font-redHat flex flex-col items-start text-white">
      <div className="flex justify-between items-center w-full px-3">
        <h1 className="font-bold md:text-3xl sm:text-2xl xs:text-xl text-[18px]">
          BlazPay Labs Corp. Global Privacy Notice
        </h1>
        {/* <div role="button" className="flex gap-x-2">
          <span className="text-base text-[#999999]">Download</span>
        </div> */}
      </div>
      <div className="md:mt-8 mt-4 w-full lg:text-[18px] xs:text-base text-[14px] xs:rounded-3xl rounded-2xl bg-[#131313] md:px-8 sm:px-6 px-5 md:py-6 sm:py-5 py-4">
        <h2 className="font-bold text-[#ff3503]">
          Date of Last Revision :&nbsp;
          <span className="font-medium text-white">12 August 2024</span>
        </h2>
        <h2 className="font-bold text-[#ff3503]">
          Effective :&nbsp;
          <span className="font-medium text-white">12 August 2024</span>
        </h2>
        <p className="mt-4 xs:text-base ss:text-[14px] text-xs text-[#999999]">
          BlazPay Labs Corp. ("BlazPay," "we," "us," or "our") is a leading
          financial technology company that provides innovative blockchain
          solutions, including the BlazPay extension, swap, bridge, and
          liquidity services. We are committed to respecting your privacy and
          safeguarding your personal information in accordance with applicable
          laws and regulations.
        </p>
        <p className="mt-4 sm:text-base text-[14px] text-[#999999]">
          This Privacy Notice applies to our website,{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.blazpay.com"
            className="text-[#3282f2]"
          >
            blazpay.com
          </a>
          , and all BlazPay products and services, including the BlazPay
          extension, swap, bridge, and liquidity solutions (collectively,
          "Services") where this Privacy Notice is posted, linked, or
          referenced. This Privacy Notice describes how we process the personal
          information collected when you access or use the Services. When you
          leave the Services, this Privacy Notice no longer applies. Any
          subsequent website, application, or service you access is subject to
          its own terms of service and privacy notice (if any) and not this
          Privacy Notice.
        </p>
        <p className="mt-4 xs:text-base ss:text-[14px] text-xs text-[#999999]">
          Please read this Privacy Notice carefully. If you do not agree with
          this Privacy Notice or any part thereof, you should not access or use
          any part of the Services. If you change your mind in the future, you
          must stop using the Services. You may exercise your rights in relation
          to your personal information as set out in this Privacy Notice and you
          may contact us at any time regarding those rights using the methods
          described in the "Contact Us" section below.
        </p>
      </div>
      <h2 className="md:text-[28px] sm:text-2xl xs:text-xl text-[18px] font-bold md:mt-8 mt-4">
        BlazPay Privacy
      </h2>
      <ul className="py-4 pl-4 flex flex-col gap-y-1 xs:text-base text-[13px] text-[#bcbcbc] list-disc">
        {policies.map((policy, index) => {
          return (
            <li className="pl-2" key={index}>
              {policy}
            </li>
          );
        })}
      </ul>
      <h2 className="md:text-2xl xs:text-xl text-base  text-[#ff3503] font-semibold md:mt-5 mt-3">
        1. Our relationship with you
      </h2>
      <div className="md:mt-5 mt-3 w-full  xs:rounded-3xl rounded-2xl bg-[#131313] md:px-8 sm:px-6 px-5 md:py-6 sm:py-5 py-4">
        <p className="xs:text-base ss:text-[14px] text-xs text-[#999999]">
          BlazPay Labs Corp. provides the Services and determines the purpose
          and means of processing your personal information. You are subject to
          the Terms of Use of the Services, which describe the contractual
          relationship between you and BlazPay. The Services can be used by
          organizations or individual users. If you're using the Services on
          behalf of an organization, your organization owns the associated
          accounts and may take certain actions in respect to your access or
          abilities to use the Services.
        </p>
      </div>
      <h2 className="md:text-2xl xs:text-xl text-base text-[#ff3503] font-semibold md:mt-5 mt-3">
        2. Personal information we collect
      </h2>
      <div className="md:mt-5 mt-3 w-full xs:rounded-3xl rounded-2xl bg-[#131313] md:px-8 sm:px-6 px-5 md:py-6 sm:py-5 py-4">
        <p className="xs:text-base ss:text-[14px] text-xs text-[#999999]">
          We collect personal information about you as described in this
          section. Some information is provided by you, while other information
          is collected automatically. All information we collect is subject to
          the use limitations described in section 3.
        </p>
      </div>
      <h2 className="md:text-xl xs:text-[18px] text-base ml-3 font-semibold text-white md:mt-8 mt-4">
        Personal information you provide to us
      </h2>
      <div className="md:mt-5 mt-2 w-full border border-[#434343]/60 xs:rounded-3xl rounded-2xl bg-[#0e0e0e] md:px-8 sm:px-6 px-5 md:py-6 sm:py-5 py-4">
        {personalInformationYouProvideToUs?.map((item, index) => {
          return (
            <div
              key={index}
              className="flex flex-col w-full xs:text-base ss:text-[14px] text-xs items-start py-[2px]"
            >
              <h3 className="text-[#ff5500] font-semibold">{item.title}</h3>
              <p className="text-[#999999]">{item.value}</p>
            </div>
          );
        })}
      </div>
      <h2 className="md:text-xl xs:text-[18px] text-[15px] ml-3 font-semibold text-white md:mt-8 mt-4">
        Information collected automatically
      </h2>
      <div className="md:mt-5 mt-2 w-full border border-[#434343]/60 xs:rounded-3xl rounded-2xl bg-[#0e0e0e] md:px-8 sm:px-6 px-5 md:py-6 sm:py-5 py-4">
        {informationCollectedAutomatically?.map((item, index) => {
          return (
            <div
              key={index}
              className="flex flex-col w-full xs:text-base ss:text-[14px] text-xs items-start py-[2px]"
            >
              <h3 className="text-[#ff5500] font-semibold">{item.title}</h3>
              <p className="text-[#999999]">{item.value}</p>
            </div>
          );
        })}
      </div>
      <h2 className="md:text-2xl xs:text-xl text-base text-[#ff3503] font-semibold md:mt-5 mt-3">
        3. How we use the personal information we collect
      </h2>
      <div className="md:mt-5 mt-3 w-full xs:rounded-3xl rounded-2xl bg-[#131313] md:px-8 sm:px-6 px-5 md:py-6 sm:py-5 py-4">
        {howWeUsePersonalInformationWeCollect?.map((item, index) => {
          return (
            <div
              key={index}
              className="flex flex-col w-full xs:text-base ss:text-[14px] text-xs items-start py-[2px]"
            >
              <h3 className="text-[#ff5500] font-semibold">{item.title}</h3>
              <p className="text-[#999999] ">{item.value}</p>
            </div>
          );
        })}
      </div>
      <h2 className="md:text-2xl xs:text-xl text-base text-[#ff3503] font-semibold md:mt-5 mt-3">
        4. Who we share personal information with
      </h2>
      <div className="md:mt-5 mt-3 w-full xs:rounded-3xl rounded-2xl bg-[#131313] md:px-8 sm:px-6 px-5 md:py-6 sm:py-5 py-4">
        {whoWeSharePersonalInformationWeCollect?.map((item, index) => {
          return (
            <div
              key={index}
              className="flex flex-col w-full items-start py-[2px]"
            >
              <h3 className="text-[#ff5500] font-semibold">{item.title}</h3>
              <p className="text-[#999999]">{item.value}</p>
            </div>
          );
        })}
      </div>
      <h2 className="md:text-2xl xs:text-xl text-base text-[#ff3503] font-semibold md:mt-5 mt-3">
        5. Your rights
      </h2>
      <div className="md:mt-5 mt-3 w-full xs:rounded-3xl rounded-2xl bg-[#131313] md:px-8 sm:px-6 px-5 md:py-6 sm:py-5 py-4">
        <p className="xs:text-base ss:text-[14px] text-xs text-[#999999]">
          You have certain rights regarding your personal information, including
          the right to access, correct, delete, or restrict processing. To
          exercise these rights, please contact us as described in the "Contact
          Us" section below.
        </p>
      </div>
      <h2 className="md:text-2xl xs:text-xl text-base text-[#ff3503] font-semibold md:mt-5 mt-3">
        6. Retension of information
      </h2>
      <div className="md:mt-5 mt-3 w-full xs:rounded-3xl rounded-2xl bg-[#131313] md:px-8 sm:px-6 px-5 md:py-6 sm:py-5 py-4">
        <p className="xs:text-base ss:text-[14px] text-xs text-[#999999]">
          We retain your personal information for as long as necessary to
          fulfill the purposes outlined in this Privacy Notice or as required by
          law.
        </p>
      </div>
      <h2 className="md:text-2xl xs:text-xl text-base text-[#ff3503] font-semibold md:mt-5 mt-3">
        7. Security & Storage
      </h2>
      <div className="md:mt-5 mt-3 w-full xs:rounded-3xl rounded-2xl bg-[#131313] md:px-8 sm:px-6 px-5 md:py-6 sm:py-5 py-4">
        <p className="xs:text-base ss:text-[14px] text-xs text-[#999999]">
          We implement reasonable security measures to protect your personal
          information. However, no internet transmission is fully secure. If you
          believe your privacy has been breached, please contact us immediately.
        </p>
      </div>
      <h2 className="md:text-2xl xs:text-xl text-base text-[#ff3503] font-semibold md:mt-5 mt-3">
        8. Data analytics & advertising
      </h2>
      <div className="md:mt-5 mt-3 w-full xs:rounded-3xl rounded-2xl bg-[#131313] md:px-8 sm:px-6 px-5 md:py-6 sm:py-5 py-4">
        <p className="xs:text-base ss:text-[14px] text-xs text-[#999999]">
          We may use data analytics to improve our Services and, subject to
          consent, engage in marketing activities. We work with third-party
          analytics providers to better understand user interactions and enhance
          our offerings.
        </p>
      </div>
      <h2 className="md:text-2xl xs:text-xl text-base text-[#ff3503] font-semibold md:mt-5 mt-3">
        9. Third party links & advertising
      </h2>
      <div className="md:mt-5 mt-3 w-full xs:rounded-3xl rounded-2xl bg-[#131313] md:px-8 sm:px-6 px-5 md:py-6 sm:py-5 py-4">
        <p className="xs:text-base ss:text-[14px] text-xs text-[#999999]">
          Our Services may contain links to third-party websites. This Privacy
          Notice does not apply to those websites. We encourage you to review
          the privacy practices of any third-party sites you visit.
        </p>
      </div>
      <h2 className="md:text-2xl xs:text-xl text-base text-[#ff3503] font-semibold md:mt-5 mt-3">
        10. Changes to our privacy policy
      </h2>
      <div className="mt-5 w-full xs:rounded-3xl rounded-2xl bg-[#131313] md:px-8 sm:px-6 px-5 md:py-6 sm:py-5 py-4">
        <p className="xs:text-base ss:text-[14px] text-xs text-[#999999]">
          We may update this Privacy Notice from time to time to reflect changes
          in our practices or legal obligations. We will notify you of any
          significant changes and encourage you to review the Privacy Notice
          periodically.
        </p>
      </div>
      <h2 className="md:text-2xl xs:text-xl text-base text-[#ff3503] font-semibold md:mt-5 mt-3">
        11. Contact us
      </h2>
      <div className="md:mt-5 mt-3 w-full xs:rounded-3xl rounded-2xl bg-[#131313] md:px-8 sm:px-6 px-5 md:py-6 sm:py-5 py-4">
        <p className="xs:text-base ss:text-[14px] text-xs text-[#999999]">
          If you have any questions or concerns about this Privacy Notice or our
          privacy practices, please contact us at:{" "}
          <a
            href="https://blazpay.com/support"
            className="text-[#3282f2]"
            target="_blank"
            rel="noreferrer"
          >
            contact@blazpay.com.
          </a>
        </p>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
