import React from "react";
import blazIcon from "../../assets/blazIcon.png";
const ErrorInfo = () => {
  return (
    <div className="px-2 text-white flex justify-center items-center lg:gap-x-4 md:gap-x-3 gap-x-1 font-medium font-redHat">
      <span className="2xl:text-[140px] xl:text-[120px] lg:text-[120px] md:text-7xl text-4xl font-bold">4</span>
      <div className="border-[#FF3503] 2xl:border-[14px] xl:border-[10px] md:border-[8px] border-[5px] 2xl:h-[100px] 2xl:w-[100px] xl:w-20 xl:h-20 lg:w-20 lg:h-20 md:w-[65px] md:h-[65px] h-8 w-8 rounded-full flex items-center justify-center">
        <img className="lg:-ml-2 -ml-1 md:h-10 lg:h-auto h-5" src={blazIcon} alt="blazicon" />
      </div>
      <span className="2xl:text-[140px] xl:text-[120px] lg:text-[120px] md:text-7xl text-4xl font-bold">4</span>
    </div>
  );
};

export default ErrorInfo;
