import { createBrowserRouter } from "react-router-dom";
import HomeLayout from "../layouts/HomeLayout";
import ErrorPage from "../pages/errorpage";
import { Suspense, lazy } from "react";
import LoadingPage from "../pages/loader";
import AboutPage from "../pages/aboutpage";
import EntryPasspage from "../pages/entrypasspage";
import Entrypass from "../pages/Entrypass";
import PrivacyPolicy from "../pages/privacypolicy";
import SupportPage from "../pages/supportpage";
const Loader = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Component {...props} />
    </Suspense>
  );
};
const HomePage = Loader(lazy(() => import("../pages/homepage")));
const Routes = createBrowserRouter([
  {
    path: "/",
    element: <HomeLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "about",
        element: <AboutPage />,
      },
      {
        path: "/entrypass",
        element: <EntryPasspage />,
      },
      { path: "entrypass/:id", element: <Entrypass /> },

      {
        path: "/privacy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/support",
        element: <SupportPage />,
      },
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
]);

export default Routes;
