import { useState } from "react";
import blazlogo from "../assets/logo.svg";
import HomeButton from "../components/buttons/HomeButton";
import HamburgerMenu from "./Hamburger";
import { useScroll } from "../context/ScrollContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useWallet } from "../hooks/useWallet";
import { useDisconnect, useWeb3Modal } from "@web3modal/ethers5/react";

const navData = [
  {
    value: "home",
    link: "home",
    newTab: false,
  },
  {
    value: "about",
    link: "about",
    newTab: false,
  },
  {
    value: "entrypass",
    link: "entrypass",
    newTab: false,
  },
  {
    value: "blog",
    link: "https://medium.com/@blazpay",
    newTab: true,
  },
  {
    value: "rewards",
    link: "https://rewards.blazpay.com",
    newTab: true,
  },
];
const Logo = () => {
  const handleLogoClick = () => {
    window.open("https://www.blazpay.com", "_self");
  };
  return (
    <div className="sm:h-10 h-8  sm:w-32 xs:w-28  w-24  xs:-ml-5 ss:-ml-10 -ml-12  relative overflow-hidden group cursor-pointer  sm:gap-y-3 sm:pt-2">
      <img
        src={blazlogo}
        onClick={handleLogoClick}
        alt="Homepage Logo"
        className="active:scale-[.96] lg:scale-100 sm:scale-90 scale-[.75] cursor-pointer transition-all  ease delay-150 absolute top-2 group-hover:-top-8"
      />
      <img
        src={blazlogo}
        onClick={handleLogoClick}
        alt="Homepage Logo"
        className="active:scale-[.96] lg:scale-100 sm:scale-90 scale-[.75] cursor-pointer transition-all ease delay-150 absolute top-12 group-hover:top-2"
      />
    </div>
  );
};
const NavMenu = ({
  navItems,
  isExpanded,
  setIsExpanded,
  address,
  className,
  showAddress,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log("location", location);
  const { scrollToFeatures, scrollToHome } = useScroll();
  const handleItemClick = (link, newTab) => {
    if (link === "about") {
      if (location.pathname == "/about") {
        setIsExpanded(false);
        scrollToFeatures();

        return;
      } else {
        setIsExpanded(false);
        navigate("/about");

        return;
      }
    } else if (link === "home") {
      if (location.pathname === "/") {
        setIsExpanded(false);
        scrollToHome();
        return;
      } else {
        setIsExpanded(false);
        navigate("/");

        return;
      }
    } else if (link === "entrypass") {
      setIsExpanded(false);
      navigate("entrypass");
    }

    if (link) {
      window.open(link, newTab ? "_blank" : "_self");
    }
  };

  return (
    <ul
      style={{
        perspective: "200px",
        backdropFilter: "blur(16px)",
      }}
      className={`
        ${
          showAddress
            ? `
            ${isExpanded ? "left-0" : "lg:left-0 left-[-100%]"}
            `
            : `${isExpanded ? "left-0" : "md:left-0 left-[-100%]"}`
        }
        
    

      flex  transition-all z-40 duration-[500ms] ease 
        justify-center 2xl:gap-x-12  sm:gap-x-8 ss:gap-x-5
         2xl:px-12 xl:px-10 sm:px-8 xs:px-5 ss:px-4 lg:py-[10px] ss:py-2  bg-[#111]/60
           border-[#ff5500] ${className}`}
    >
      {navItems?.map((item, index) => {
        return (
          <li
            role="button"
            onClick={() => handleItemClick(item.link, item.newTab)}
            className="font-redHat sm:text-base hover:text-[#ff5500]  text-[13px] transition-all duration-150 ease hover:scale-[.96] cursor-pointer font-semibold text-white/90 capitalize"
            key={index}
          >
            {item?.value}
          </li>
        );
      })}
      {showAddress && address && (
        <li className="text-[13px] ss:hidden hover:opacity-70 transition-all duration-[200ms] ease border border-[#ff3503] px-4 py-1 rounded-full font-bold text-white text-center flex gap-x-2 items-center justif-center">
          Wallet Address :{" "}
          <span className="text-[#ff3503] font-medium">{address}</span>
        </li>
      )}
    </ul>
  );
};
const Navbar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { pathname } = useLocation();
  const { isConnected, address } = useWallet();
  const { open } = useWeb3Modal();

  const { disconnect } = useDisconnect();
  const showAddress =
    pathname === "/entrypass" || pathname.startsWith("/entrypass/");

  const handleLaunchApp = () => {
    window.open("https://www.defi.blazpay.com", "_blank");
  };
  const formatAddress = (address) => {
    return address.slice(0, 4) + "...." + address.slice(-4);
  };

  return (
    <header
      className={`fixed top-0  z-50 left-1/2 lg:py-6 py-4 sm:px-8 xs:px-4 pr-3  -translate-x-1/2 xl:container w-full 2xl:px-16 xl:px-8 px-16 mx-auto
     flex items-center justify-between  
    `}
    >
      <Logo />
      <NavMenu
        navItems={navData}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        showAddress={showAddress && isConnected}
        address={formatAddress(address || "")}
        className={`${
          isConnected && showAddress
            ? " xl:ml-32 lg:ml-28 lg:relative fixed lg:w-fit w-full lg:flex-row flex-col lg:gap-y-0 gap-y-5 top-0 lg:h-fit h-72 items-center lg:rounded-full lg:border-[1.2px] border-r-[1.2px]"
            : "md:relative fixed md:w-fit w-full md:flex-row flex-col md:gap-y-0 gap-y-5 top-0 md:h-fit h-72 items-center md:rounded-full md:border-[1.2px] border-r-[1.2px]"
        } `}
      />
      {showAddress ? (
        isConnected ? (
          <div
            className={`flex gap-x-2 lg:relative absolute   lg:right-0 sm:right-16 right-12`}
          >
            <span
              style={{
                backdropFilter: "blur(16px)",
              }}
              className="xl:px-6 px-4 py-1 bg-[#111]/60 text-white rounded-full border border-[#ff3503] sm:text-[13px] text-xs  ss:flex hidden  items-center justify-center"
            >
              {formatAddress(address)}
            </span>
            <HomeButton handleClick={() => disconnect()} title={"Disconnect"} />
          </div>
        ) : (
          <HomeButton
            title={"Connect"}
            handleClick={() => open({ view: "Connect" })}
            className={`${
              isConnected && showAddress
                ? "  lg:right-0 sm:right-16 right-12"
                : "md:relative absolute  md:right-0 sm:right-16 right-12"
            }  `}
          />
        )
      ) : (
        <HomeButton
          title={"Launch App"}
          handleClick={handleLaunchApp}
          className="md:relative absolute  md:right-0 sm:right-16 right-12"
        />
      )}
      <HamburgerMenu
        className={`${
          isConnected && showAddress ? "lg:hidden block" : "md:hidden block"
        }  z-50`}
        isExpanded={isExpanded}
        handleClick={() => setIsExpanded((x) => !x)}
      />
    </header>
  );
};

export default Navbar;
