import { Outlet, useLocation } from "react-router-dom";
import Navbar from "./Navbar";

const HomeLayout = () => {
  const location = useLocation();
  const allowedPaths = ["/", "/about", "/entrypass", "/support"];
  const showNavbar =
    allowedPaths.includes(location.pathname) ||
    location.pathname.startsWith("/entrypass/");
  return (
    <div>
      {showNavbar && <Navbar />}
      <Outlet />
    </div>
  );
};

export default HomeLayout;
