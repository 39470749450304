import { RouterProvider } from "react-router-dom";
import Routes from "./routes";
import { ScrollProvider } from "./context/ScrollContext";
import { WalletProvider } from "./context/WalletContext";
import "./services/walletConnect.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <ScrollProvider>
      <WalletProvider>
        <RouterProvider router={Routes} />
        <ToastContainer
          position="top-right"
          className={"fixed z-[10000000]"}
          theme="dark"
        />
      </WalletProvider>
    </ScrollProvider>
  );
}

export default App;
