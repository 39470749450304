import React from "react";
import { CiMail } from "react-icons/ci";
import "../../styles/support.css";
import TextCard from "../../components/support/TextCard";
import ButtonCard from "../../components/support/ButtonCard";
import ArticleCard from "../../components/support/ArticleCard";
const topics = [
  { id: 1, topic: "Getting started", url: "/submit" },
  { id: 2, topic: "Add a Payment method", url: "/submit" },
  { id: 3, topic: "Managing my Account", url: "/submit" },
  { id: 4, topic: "Fraud and Spam activity", url: "/submit" },
  { id: 5, topic: "Privacy and Security", url: "/submit" },
  { id: 6, topic: "Taxes", url: "/submit" },
];
const products = [
  { id: 1, product: "Blazpay Wallet", url: "/support" },
  { id: 2, product: "Blazpay Extension", url: "/submit" },
  { id: 3, product: "DApp", url: "/submit" },
  { id: 4, product: "Features", url: "/submit" },
  { id: 5, product: "Blazpay NFTs", url: "/submit" },
  { id: 6, product: "Token and Coin", url: "/submit" },
];
const managingAccounts = [
  { value: "How to verify your Identity on Blazpay", link: "" },
  { value: "2-step verification troubleshooting", link: "" },
  { value: "Submit a Complaint", link: "" },
];
const sendAndReceiveCrypto = [
  { value: "Why is my Transaction Pending?", link: "" },
  { value: "How to send and receive crypto", link: "" },
  { value: "Assets on multiple Networks", link: "" },
];
const cryptoBuySell = [
  { value: "Cash out your Balance", link: "" },
  { value: "Why am i unable to buy Crypto?", link: "" },
  { value: "Blazpay Pricing and fees Disclosures", link: "" },
];
const blazpayEarn = [
  { value: "Token and Region Eligible for staking", link: "" },
  { value: "Earn Rewards with staking", link: "" },
  { value: "Learning Rewards", link: "" },
];
const Support = ({ setIsRequested, setSelectedIssue }) => {
  const handleIssuesClick = () => {
    setIsRequested(true);
  };
  const handleButtonClick = (issue) => {
    setSelectedIssue(issue);
    setIsRequested(true);
  };
  return (
    <div>
      <div
        role="button"
        onClick={handleIssuesClick}
        className="lg:w-[700px] lg:h-[70px] sm:w-[400px] w-56 h-12 mx-auto btn justify-between lg:rounded-[79px] flex items-center sm:p-2 lg:p-8 md:p-4 cursor-pointer mt-[-40px] "
      >
        <h1 className="text-[#898989] lg:text-[30px] font-RedHat text-base ml-3 sm:text-xl lg:leading-10 lg:ml-10">
          Send your Issues
        </h1>
        <CiMail className="lg:w-[50px] w-10 h-6 lg:h-[40px] text-[#9E9E9E] mr-2" />
      </div>

      <div className="lg:w-9/12 sm:w-11/12 mx-auto mt-20 mb-20">
        <div className="flex flex-col sm:mb-10 mb-8">
          <span className="font-Inter lg:text-[32px] sm:text-2xl lg:mb-20 sm:mb-6 mb-4 ml-3 text-xl sm:ml-5 text-white ">
            Help by Topics
          </span>
          <div className="flex lg:gap-10 sm:gap-6 gap-4 flex-wrap justify-center ">
            {topics.map((item) => {
              return (
                <ButtonCard
                  key={item.id}
                  title={item.topic}
                  handleClick={() => handleButtonClick(item.topic)}
                />
              );
            })}
          </div>
        </div>
        <div className="flex flex-col">
          <span className="font-Inter lg:text-[32px] sm:text-2xl lg:mt-20 sm:mb-6 sm:ml-5 mb-4 ml-3 text-xl lg:mb-20 text-white ">
            Help by Products
          </span>
          <div className="flex lg:gap-10  sm:gap-6 gap-4 flex-wrap justify-center ">
            {products.map((item) => {
              return (
                <ButtonCard
                  key={item.id}
                  title={item.product}
                  handleClick={() => handleButtonClick(item.product)}
                />
              );
            })}
          </div>
        </div>
        <div className="flex flex-col text-white mt-5 lg:w-full sm:w-11/12 sm:mx-auto ">
          <span className="font-Inter lg:text-[32px] sm:text-2xl lg:mt-20 sm:mt-10 ml-3 text-xl ">
            Top Articles
          </span>

          <div className="lg:flex sm:flex justify-between lg:w-full sm:w-full mx-auto">
            <div className="sm:text-lg lg:mb-0 sm:mb-0 mb-14">
              <TextCard title="Managing my Account" />
              {managingAccounts.map((item, index) => {
                return (
                  <ArticleCard
                    key={index}
                    handleClick={() => {}}
                    title={item.value}
                  />
                );
              })}

              <TextCard title="Crypto Buy and Sell" />

              {cryptoBuySell.map((item, index) => {
                return (
                  <ArticleCard
                    key={index}
                    handleClick={() => {}}
                    title={item.value}
                  />
                );
              })}
            </div>
            <div className="sm:text-lg">
              <TextCard title={"Sending and receiving crypto"} />
              {sendAndReceiveCrypto.map((item, index) => {
                return (
                  <ArticleCard
                    key={index}
                    handleClick={() => {}}
                    title={item.value}
                  />
                );
              })}

              <TextCard title={"Blazpay Earn"} />
              {blazpayEarn.map((item, index) => {
                return (
                  <ArticleCard
                    key={index}
                    handleClick={() => {}}
                    title={item.value}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
