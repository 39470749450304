import React, { FormEvent, useEffect, useState } from "react";
import "../../styles/support.css";
import { CiMail } from "react-icons/ci";
import { CiUser } from "react-icons/ci";
import { RiArrowDropDownLine } from "react-icons/ri";
import { toast } from "react-toastify";
import Vector from "../../assets/supportArrowTop.svg";

const options = [
  "Getting started",
  "Add a Payment method",
  "Managing my Account",
  "Fraud and Spam activity",
  "Privacy and Security",
  "Taxes",
  "Blazpay Wallet",
  "Blazpay Extension",
  "DApp",
  "Features",
  "Blazpay NFTs",
  "Token and Coin",
];
const SupportSubmit = ({ setIsRequested, selectedIssue }) => {
  const subject = selectedIssue;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: subject,
    description: "",
    images: [],
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    setFormData((prevData) => ({ ...prevData, images: files }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formPayload = new FormData();
    formPayload.append("name", formData.name);
    formPayload.append("email", formData.email);
    formPayload.append("subject", formData.subject);
    formPayload.append("description", formData.description);
    formData.images.forEach((image, index) => {
      formPayload.append(`images`, image);
    });

    try {
      const response = await fetch("https://api-v2.blazpay.com/api/submit", {
        method: "POST",
        body: formPayload,
      });

      if (!response.ok) {
        throw new Error("Error submitting the form");
      } else {
        toast.success("Request sent successfully");
        setFormData({
          name: "",
          email: "",
          subject: "Select a topic",
          description: "",
          images: [],
        });

        event.target.reset();
        setIsRequested(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="flex flex-col 2xl:pb-20 sm:pb-16 pb-10 lg:overflow-x-visible overflow-x-hidden">
      <form
        className="flex lg:flex-row md:flex-row sm:flex-row flex-col text-white"
        onSubmit={handleSubmit}
      >
        <div className="lg:w-1/2 md:w-1/2 sm:w-1/2 lg:ml-0 md:ml-0 sm:ml-0 ">
          <div className="lg:ml-5 lg:mt-[-65px] lg:mb-16 ml-8 sm:mb-20">
            <h1 className="lg:text-8xl leading-[54px] lg:ml-20 lg:mb-4 text-4xl md:text-6xl sm:text-6xl font-bold flex items-center lg:gap-8 gap-2 text-white">
              Hey!
              <span>
                <img className="lg:w-14" src={Vector} alt="" />
              </span>
            </h1>
            <h1 className="lg:text-6xl text-2xl md:text-3xl sm:text-3xl leading-[54px] mt-[-25px] sm:mt-0 lg:mt-0 lg:ml-20 lg:mb-8 mb-10 font-bold text-white">
              how can we help?
            </h1>
          </div>
          <InputField
            type="text"
            placeholder="Your Name"
            name="name"
            value={formData.name}
            handleChange={handleChange}
            icon={<CiUser className="w-[30px] h-[30px] text-[#9E9E9E] " />}
          />
          <InputField
            type="email"
            placeholder="Your Name"
            name="email"
            value={formData.email}
            handleChange={handleChange}
            icon={<CiMail className="w-[30px] h-[30px] text-[#9E9E9E] " />}
          />
        </div>

        <div className="lg:w-1/2 md:w-1/2 sm:w-1/2 lg:mt-[-65px]  lg:ml-0 ">
          <div className="flex lg:w-[70%] w-9/12 sm:w-10/12 mx-auto justify-between items-center text-xl mb-2 relative">
            <select
              className="w-full bg-black appearance-none cursor-pointer outline-none border-b pb-2"
              name="subject"
              id="subject"
              value={formData.subject}
              onChange={handleChange}
            >
              <option className="bg-black text-white">Select a topic</option>
              {options.map((option, index) => {
                return (
                  <option
                    key={index}
                    className="bg-black text-white"
                    value={option}
                  >
                    {option}
                  </option>
                );
              })}
            </select>
            <RiArrowDropDownLine className="text-4xl absolute right-0" />
          </div>
          <div className="relative flex flex-col lg:w-[70%] w-9/12 sm:w-10/12 mx-auto text-xl">
            <textarea
              className="w-full bg-inherit border-b text-base pt-2 outline-none resize-none text-white placeholder-transparent"
              name="description"
              value={formData.description}
              onChange={handleChange}
              rows={5}
              maxLength={1160}
              placeholder="Description . . ."
              id="textarea"
            />
            {!formData.description && (
              <span className="absolute bottom-5 left-0 text-gray-400 text-sm pointer-events-none">
                Description . . .
              </span>
            )}
          </div>

          <p className="text-white lg:w-[70%] w-9/12 sm:w-10/12 text-[8.4px] mx-auto lg:text-xs mt-2 mb-6">
            Please enter the details and provide the necessary screenshot of the
            request a member of our support staff will respond as soon as
            possible .
          </p>
          <div className="flex items-center lg:ml-28 w-[70%] h-[40px] mx-auto  bg-inherit input outline-none text-base font-RedHat rounded-[79px] py-5 mb-5">
            <input
              className="mx-auto file:bg-inherit lg:file:mr-4 lg:file:py-2 lg:file:px-4
                                file:rounded-full file:border-0
                                file:text-sm file:font-semibold
                                file:text-[#898989] file:cursor-pointer text-[#898989]
                                "
              type="file"
              accept="image/*"
              name="images"
              onChange={handleFileChange}
              multiple
              required
            />
          </div>

          <div className="w-7/12 lg:ml-28 mx-auto flex lg:block">
            <button className="box input w-9/12 lg:w-5/12 h-12 mx-auto font-bold">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SupportSubmit;

const InputField = ({ type, name, placeholder, value, handleChange, icon }) => {
  return (
    <div className="flex items-center lg:w-7/12 w-9/12 sm:w-10/12 mx-auto sm:ml-8 lg:ml-24 text-lg lg:text-xl mb-8 border-b pb-2 ">
      <input
        className="bg-inherit outline-none w-full"
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        required
      />
      {icon}
    </div>
  );
};
