import React, { useState, useEffect } from "react";
import SupportSubmit from "../components/support/SupportSubmit";
import Support from "../components/support/Support";
import "../styles/support.css";
import { useLocation } from "react-router-dom";
import AboutUsFooter from "../components/aboutus/AboutUsFooter";
const SupportPage = () => {
  const { pathname } = useLocation();
  const [isRequested, setIsRequested] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="min-h-screen pt-16">
      <div className="bg-img-support lg:h-[400px] sm:h-[300px] h-52 flex flex-col items-center justify-center lg:mt-10 ">
        <div className="lg:w-[904px] h-20 flex items-center justify-center leading-[80px] ">
          <h1 className="font-bold lg:text-[64px] lg:leading-[80px] sm:text-4xl text-2xl main-text font-Lexend  ">
            {isRequested ? " Submit a Request" : "Hey! How can we Help you?"}
          </h1>
        </div>
      </div>
      {isRequested ? (
        <SupportSubmit
          setIsRequested={setIsRequested}
          selectedIssue={selectedIssue}
        />
      ) : (
        <Support
          setIsRequested={setIsRequested}
          setSelectedIssue={setSelectedIssue}
        />
      )}
      <AboutUsFooter />
    </div>
  );
};

export default SupportPage;
